import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  TopbarItem,
  TopbarDivider,
  Button,
  ButtonIcon,
  TopbarDropdown,
  TopbarDropdownItem,
  TopbarDropdownDivider,
  IconTypeUser,
  MessageStatus,
  Spinner
} from '@make.tv/lib-web-uicomponents';
import { createGlobalStyle } from 'styled-components';

import { TopNavRoleGuard, RealRoleGuard } from 'Components/auth/role-guard';
import { ImpersonateModal } from 'Components/auth/impersonate-modal';
import { CurrentTime } from 'Components/current-time';
import { ROLE_NOC } from 'Constants';
import { useRouter } from 'Context/hooks';
import { useUser } from 'Context/user';
import { useAuth } from 'Context/auth';
import { useNavContext } from 'Context/nav-context';
import { Users } from 'Services/resources';

import {
  AccountId,
  ImpersonationExit,
  MessageStatusContainer,
  Nav,
  PrimaryNavbar,
  LogoArea,
  PortalLogo,
} from './styles';
import { SecondaryNavbar } from './secondary-navbar';

/**
 * Component for rendering the whole header area
 * Including logo, navigation, clock, user dropdown
 */

const GlobalStyle = createGlobalStyle`
  .ltn .dropdown-menu {
    z-index: 9999;
  }
`;

export const PageHeader = () => {
  const {
    user, fetchUser, isImpersonating, impersonatedAccount
  } = useUser();
  const { routes, pathname } = useRouter();
  const { logout } = useAuth();
  const { titleOverwrite, versionOutdated } = useNavContext();
  const [impersonationLoading, setImpersonationLoading] = useState(false);

  const activeRoute = routes.find((d) => pathname.indexOf(`/${d.path}`) === 0);

  const exitImpersonation = useCallback((e) => {
    e.stopPropagation();

    if (impersonationLoading) {
      return;
    }

    setImpersonationLoading(true);
    // if passed account id is the same as the user's own account id, remove impersonation
    Users.impersonate(user.user_id, user.account_id)
      .catch(() => {})
      .then(fetchUser)
      .then(() => {
        setImpersonationLoading(false);
        window.location.reload();
      });
  // eslint-disable-next-line
  }, [impersonationLoading, user?.user_id, user?.account_id, fetchUser]);
  return (
    <>
      <GlobalStyle />
      <Nav>
        <PrimaryNavbar
          logo={<LogoArea><PortalLogo /></LogoArea>}
          menuLeft={(
            <>
              {routes.filter((route) => route.ui === 'menuLeft').map((route) => (
                <TopNavRoleGuard {...route.auth} key={route.path} title={titleOverwrite && titleOverwrite[route.path] ? `${route.title} (${titleOverwrite[route.path]})` : route.title}>
                  <TopbarItem>
                    <Link to={`/${route.path}`}>
                      <Button color="tertiary" className={activeRoute && activeRoute.path === route.path && '--active'}>{titleOverwrite && titleOverwrite[route.path] ? `${route.title} (${titleOverwrite[route.path]})` : route.title}</Button>
                    </Link>
                  </TopbarItem>
                </TopNavRoleGuard>
              ))}
            </>
        )}
          menuRight={(
            <div className="d-flex items-center">
              {routes.filter((route) => route.ui === 'menuRight').map((route) => (
                <TopNavRoleGuard {...route.auth} key={route.path}>
                  <TopbarItem>
                    <Link to={`/${route.path}`}>
                      <Button color="tertiary" className={activeRoute && activeRoute.path === route.path && '--active'}>
                        {titleOverwrite && titleOverwrite[route.path] ? `${route.title} (${titleOverwrite[route.path]})` : route.title}
                      </Button>
                    </Link>
                  </TopbarItem>
                </TopNavRoleGuard>
              ))}
              <TopbarDivider />
              <CurrentTime />
              {user && (
                <>
                  <TopbarItem>
                    <TopbarDropdown position="right" trigger={<ButtonIcon color="tertiary" icon={<IconTypeUser />} />}>
                      <TopbarDropdownItem style={{ flexFlow: 'column', height: 'auto' }}>
                        {user.user_id}
                        <div className="d-flex items-center">
                          <AccountId warn={isImpersonating}>
                            {isImpersonating ? impersonatedAccount : user.account_id}
                          </AccountId>
                          {isImpersonating && !impersonationLoading && (
                            <ImpersonationExit onClick={exitImpersonation} />
                          )}
                          {impersonationLoading && (
                            <span className="d-flex items-center" style={{ marginLeft: '5px' }}><Spinner /></span>
                          )}
                        </div>
                      </TopbarDropdownItem>
                      <TopbarDropdownDivider />
                      {routes.filter((route) => route.ui === 'menuUser').map((route) => (
                        <Link to={`/${route.path}`} key={route.path}>
                          <TopbarDropdownItem>
                            {titleOverwrite && titleOverwrite[route.path] ? `${route.title} (${titleOverwrite[route.path]})` : route.title}
                          </TopbarDropdownItem>
                        </Link>
                      ))}
                      <RealRoleGuard role={ROLE_NOC}>
                        <ImpersonateModal>
                          {({ open }) => (
                            <TopbarDropdownItem onClick={open}>
                              Impersonate
                            </TopbarDropdownItem>
                          )}
                        </ImpersonateModal>
                      </RealRoleGuard>
                      <TopbarDropdownItem onClick={logout}>Logout</TopbarDropdownItem>
                    </TopbarDropdown>
                  </TopbarItem>
                </>
              )}
            </div>
        )}
        />

        <SecondaryNavbar {...{ activeRoute, pathname, titleOverwrite }} />
      </Nav>

      {versionOutdated && (
        <MessageStatusContainer>
          <MessageStatus level="warning" style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
            Clear your browser cache or hard refresh your browser to get the latest version
          </MessageStatus>
        </MessageStatusContainer>
      )}
    </>
  );
};
