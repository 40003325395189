import styled from 'styled-components';

import { IconEditRemove, Topbar as TopbarComponent } from '@make.tv/lib-web-uicomponents';
import { ReactComponent as LogoPortal } from 'assets/images/portal-logo.svg';
import { modifier } from 'Styles/utils';

export const Nav = styled.nav`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  .ltn.topbar {
    box-shadow: none;
  }
`;

export const PrimaryNavbar = styled(TopbarComponent)`
  width: 1441.5px;
  height: 64.5px;
  margin: 0 233.5px 0 0;
  padding: 0 829.5px 0.5px 0;
  background-color: #171a1c;

  .ltn.top-menu__left {
    width: 870px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    margin: 16px 0;
    padding: 0;
  }

  .ltn.topbar-logo {
    margin-right: 0;
    background: none;
  }
`;

export const PortalLogo = styled(LogoPortal)`
  width: 95.4px;
  height: 15px;
  margin: 7.9px 0 9.4px;
  object-fit: contain;
`;

export const LogoArea = styled.div`
  width: 129px;
  height: 64px;
  flex-grow: 0;
  padding: 16.1px 17.6px 15.6px 16px;
`;

export const SecondaryNavbar = styled(TopbarComponent)`
  .ltn.topbar-logo {
    padding: 0;
  }
`;

export const SubNav = styled.div`
  display: flex;
  gap: 8px;

  .button#newBookingBtn {
    margin: 0 8px 0 12px;
  }
`;

export const AccountId = styled.div`
  ${modifier('warn')`
    color: ${({ theme }) => theme.colors.textWarning};
  `}

  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

export const ImpersonationExit = styled(IconEditRemove).attrs({ size: 'sm' })`
  margin-left: 3px;
  color: ${({ theme }) => theme.colors.textWarning};
  &:hover {
    cursor: pointer;
  }
`;

export const MessageStatusContainer = styled.div`
  background: rgba(200, 121, 25, 0.3);
  border-radius: 2px;
  display: flex;
  justify-content: center;
`;
