import loginRoutes from 'Modules/login/routes';
import accountsRoutes from 'Modules/accounts/routes';
import accountAdminRoutes from 'Modules/account-admin/routes';
import adminRoutes from 'Modules/admin/routes';
import apiRoutes from 'Modules/api/routes';
import contactRoutes from 'Modules/contact/routes';
import directoryRoutes from 'Modules/directory/routes';
import helpRoutes from 'Modules/help/routes';
import monitoringRoutes from 'Modules/monitoring/routes';
import nocRoutes from 'Modules/noc/routes';
import notificationsRoutes from 'Modules/notifications/routes';
import profileRoutes from 'Modules/profile/routes';
import reportRoutes from 'Modules/report/routes';
import schedulingRoutes from 'Modules/scheduling/routes';
import scheduleRoutes from 'Modules/schedule/routes';
import watchRoutes from 'Modules/watch/routes';
import forgotPasswordRoutes from 'Modules/forgot-password/routes';
import resetPasswordRoutes from 'Modules/reset-password/routes';

export const anonymousRoutes = [
  loginRoutes,
  forgotPasswordRoutes,
  resetPasswordRoutes,
];

export const authenticatedRoutes = [
  monitoringRoutes,
  schedulingRoutes,
  watchRoutes,
  notificationsRoutes,
  directoryRoutes,
  reportRoutes,
  nocRoutes,
  accountsRoutes,
  accountAdminRoutes,
  adminRoutes,
  apiRoutes,
  helpRoutes,
  contactRoutes,
  profileRoutes,
  scheduleRoutes,
];

export default [
  ...anonymousRoutes,
  ...authenticatedRoutes.map(({ auth, ...route }) => ({
    ...route,
    auth: { type: 'private', ...(auth || {}) },
  })),
];
